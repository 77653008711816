@import 'variable';

.bg-background2 {
  background-color: @background;
}

.medium-text-color {
  color: var(--text-medium);
}

.system-red-color {
  color: var(--system-red);
}

.color-white {
  color: var(--color-white);
}

.color-main {
  color: @primary;
}

.notice-due-date {
  color: @system-red;
}

.color-blue {
  color: @system-Links;
}

.color-red {
  color: @system-red;
}

.color-orange {
  color: @system-warning;
}

.bg-blue {
  background-color: @system-Links;
}

.bg-red {
  background-color: @system-red;
}

.bg-orange {
  background-color: @system-warning;
}

.bg-background {
  background-color: @background;
}

.status-form-verified {
  background-color: @primary-primary-tint-1;
  color: @system-Links;
}

.status-for-verification {
  background-color: @system-Success-fill;
  color: @system-green;
}

.status-failed {
  background-color: @system-error-fill;
  color: @system-red;
}

.status-success {
  background-color: @system-Success-fill;
  color: @system-green;
}

.status-pending {
  background-color: @system-warning-fill;
  color: @system-warning;
}

.status-closed {
  background-color: @system-error-fill;
  color: @system-red;
}

.status-replied {
  background-color: @system-Success-fill;
  color: @system-green;
}

.status-default {
  background-color: var(--text-medium);
}

.bg-warning {
  background-color: var(--system-warning);
}

.bg-blue {
  background-color: var(--system-Links);
}

.bg-orange {
  background-color: var(--system-orange);
}

.text-white {
  color: var(--color-white);
}

.dark-text {
  color: var(--textDark);
}

.text-medium {
  color: var(--text-medium);
}
