:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --primary-Main: #7a2976;
  --primary-Tint-01: #d966d3;
  --primary-Tint-02: #f2ccf0;
  --primary-Shade-01: #4d0049;
  --primary-Shade-02: #260024;
  --color-grey-light: #f2f2f2;

  --background-1: #ffffff;
  --background-2: #fbf9fb;
  --background: #f2f4f7;
  --breadcrumbLink: #8c8c8c;
  --breadcrumbLinkActive: #141414;
  --textDark: #161616;
  --character-primary-85: #000000d9;
  --text-medium: #777777;
  --stroke-dark: #d6d6d6;
  --stroke-light: #e7e7e7;

  --Neutral-02: #e6e6e6;
  --Neutral-08: #4c4c4c;
  --Background-Background_01: #fff;

  --background-ai: #192642;
  --border-ai: #0051ff;
  --border-calendar: #1890ff;
  --primary-primary-shade-1: #004373;

  --system-warning: #fa8c16;
  --system-Links: #006fc0;
  --system-orange: #e64e2c;
  --shadow-color: #bdb6b6;

  --font-family: 'Poppins', sans-serif;

  --green: #04724d;
  --tint-green: #ccf2e5;
  --system-green: #3bb665;
  --system-Success-fill: #f6ffed;
  --system-Links: #006fc0;
  --primary-primary-tint-1: #cce2f2;
  --system-red: #e64e2c;
  --system-error-fill: #fff1f0;
  --system-warning: #fa8c16;
  --system-warning-fill: #fffbe6;
}
/*breakpoint mixin start here*/
@screen1750: ~'only screen and (max-width: 1750px)';
@screen1500: ~'only screen and (max-width: 1500px)';
@screen1460: ~'only screen and (max-width: 1460px)';
@screen1440: ~'only screen and (max-width: 1440px)';
@screen1360: ~'only screen and (max-width: 1360px)';
@screen1350: ~'only screen and (max-width: 1350px)';
@screen1260: ~'only screen and (max-width: 1260px)';
@screen1170: ~'only screen and (max-width: 1170px)';
@screen1080: ~'only screen and (max-width: 1080px)';
@screen1024: ~'only screen and (max-width: 1024px)';
@screen991: ~'only screen and (max-width: 991px)';
@screen900: ~'only screen and (max-width: 900px)';
@screen768: ~'only screen and (max-width: 768px)';
@screen767: ~'only screen and (max-width: 767px)';
@screen640: ~'only screen and (max-width: 640px)';
@screen540: ~'only screen and (max-width: 540px)';
@screen414: ~'only screen and (max-width: 414px)';
@screen375: ~'only screen and (max-width: 375px)';
@screen360: ~'only screen and (max-width: 360px)';
@screen320: ~'only screen and (max-width: 320px)';
/*breakpoint mixin end here*/

@container: 1440px;

@primary: var(--primary-Main);
@background1: var(--background-1);
@background2: var(--background-2);
@background: var(--background);
@breadcrumbLink: var(--breadcrumbLink);
@shadowColor: var(--shadow-color);

@system-green: var(--system-green);
@system-Success-fill: var(--system-Success-fill);
@system-Links: var(--system-Links);
@primary-primary-tint-1: var(--primary-primary-tint-1);
@primary-primary-shade-1: var(--primary-primary-shade-1);
@system-red: var(--system-red);
@system-error-fill: var(--system-error-fill);
@system-warning: var(--system-warning);
@system-warning-fill: var(--system-warning-fill);
