.noticeboard-slider {
  padding: 40px 0;
  .slick-dots li button {
    background: #37455f;
  }
  .slick-dots li.slick-active button {
    background: var(--system-Links);
  }
  .notice-board {
    .tax-category-judge.notice-board-redirect-section {
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
    }
    .notice-board-container {
      @media@screen991 {
        gap: 24px;
      }
      @media@screen767 {
        flex-direction: column;
        padding-bottom: 20px;
      }
      .vector {
        top: 0;
        left: 239px;
        position: absolute;
        width: 119.277px;
        height: 111.976px;
        transform: rotate(44.995deg);
        @media@screen767 {
          display: none;
        }
      }
      .calendar {
        max-width: 519px;
        width: 100%;
        img {
          width: 100%;
          display: block;
        }
      }
      .text-medium {
        color: var(--text-medium);
      }
      .text-dark {
        color: var(--textDark);
      }
      h2.semibold {
        font-size: 34px;
        line-height: 44px;
        font-style: normal;
        font-weight: 600;
        margin: 0;
        @media@screen767 {
          font-size: 28px;
          line-height: 40px;
        }
      }
      h4.medium {
        font-size: 20px;
        line-height: 30px;
        font-style: normal;
        font-weight: 500;
        margin: 0;
        @media@screen767 {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }
}
.notice-board-feature {
  background: var(--primary-primary-shade-1);
  padding: 80px 0;
  color: var(--Background-Background_01);
  @media@screen991 {
    padding: 60px 0;
  }
  h2 {
    text-align: center;
    font-size: 34px;
    line-height: 44px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
    margin-bottom: 60px;
    @media@screen767 {
      font-size: 28px;
      line-height: 36px;
      margin-bottom: 40px;
    }
  }
  .feature-card {
    display: flex;
    gap: 16px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
    .icon {
      height: 32px;
      width: 32px;
      background: #fff;
      border-radius: 6px;
      flex-shrink: 0;
    }
    h4 {
      font-size: 22px;
      line-height: 28px;
      font-style: normal;
      font-weight: 500;
      margin: 0;
    }
    p {
      margin-top: 0;
    }
  }
}
footer {
  background: #fff;
  padding: 40px 0;
  .footer-top-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    @media@screen991 {
      flex-direction: column;
      text-align: left;
      align-items: flex-start;
    }
    .footer-top-left {
      @media@screen991 {
        text-align: left;
      }
      span {
        display: inline-block;
      }
      p {
        margin: 0;
      }
      a {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--textDark);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        margin: 4px 0;
      }
    }
    .footer-top-right {
      @media@screen991 {
        margin-top: 20px;
      }
      ul {
        display: flex;
        align-items: center;
        gap: 32px;
        list-style: none;
        padding: 0;
        margin: 0;
        @media@screen991 {
          gap: 16px;
        }
        @media@screen767 {
          gap: 8px;
          flex-wrap: wrap;
        }
        li {
          a {
            font-size: 16px;
            color: var(--textDark);
            @media@screen767 {
              font-size: 14px;
            }
            &:hover {
              color: var(--system-Links);
            }
          }
        }
      }
    }
  }
  .logicwind-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
    @media@screen991 {
      flex-direction: column;
      gap: 4px;
      align-items: flex-start;
    }
  }
}

.cms-page {
  h2 {
    font-size: 22px;
    line-height: 28px;
  }
  h5 {
    font-size: 18px;
    line-height: 24px;
  }
  p {
    font-size: 16px;
  }
  ul {
    padding-left: 20px;
    li {
      font-size: 16px;
    }
  }
}

.content-center {
  max-width: 1168px;
  margin: auto;
}

.btn-wrapper {
  display: flex;
  gap: 24px;
  @media@screen991 {
    gap: 16px;
  }
  @media@screen767 {
    gap: 12px;
  }
}
.feature-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
  @media@screen991 {
    flex-direction: column;
    gap: 30px;
  }
  .feature-banner {
    flex: 1;
    img {
      width: 100%;
      @media@screen991 {
        max-width: 600px;
        margin: auto;
      }
    }
  }
  .feature-content {
    flex: 1;
  }
}
.home-container {
  .noticeboard-slider {
    width: 100%;
    background-repeat: no-repeat;
    background-image: url('../assets/images/hero-section.png');
    background-position: center;
    .hero-section {
      .hero-title {
        font-size: 40px;
        font-weight: 900;
        color: var(--textDark);
        max-width: 248px;
        width: 100%;
        text-align: center;
        margin-bottom: 12px;
        @media @screen767 {
          font-size: 32px;
        }
      }
      .hero-description {
        font-size: 20px;
        color: var(--text-medium);
        max-width: 479px;
        text-align: center;
        width: 100%;
        @media @screen767 {
          font-size: 16px;
        }
      }
    }
  }
  h1 {
    font-size: 32px;
    font-style: normal;
    font-weight: 900;
    line-height: 38px;
    @media @screen1024 {
      font-size: 30px;
      line-height: 36px;
    }
    @media @screen767 {
      font-size: 24px;
      font-style: normal;
      line-height: 32px;
    }
  }
  h2 {
    font-size: 20px;
  }
  .direct-tax-notice-board-feature {
    background: linear-gradient(180deg, #ffffff 0%, #fff9ff 100%);
    padding-bottom: 40px;
    @media @screen767 {
      flex-direction: column-reverse;
    }
    &__image {
      width: 50%;
      @media @screen767 {
        width: 100%;
        padding: 0 16px;
      }
      img {
        width: 100%;
        display: block;
      }
    }
    .direct-tax-card-container {
      width: 50%;
      padding-right: 64px;
      padding-left: 24px;
      @media @screen767 {
        width: 100%;
        padding: 0 16px;
      }
      &__wrapper {
        max-width: 504px;
        width: 100%;
        margin: auto;
        gap: 16px;
        @media @screen767 {
          max-width: 100%;
        }
      }
    }
  }
  .indirect-tax-notice-board-feature {
    background: linear-gradient(180deg, #ffffff 0%, #f3fffb 100%);
    padding-bottom: 40px;
    @media @screen767 {
      flex-direction: column;
    }
    &__image {
      width: 50%;
      @media @screen767 {
        width: 100%;
        padding: 0 16px;
      }
      img {
        width: 100%;
        display: block;
      }
    }
    .gst-card-container {
      width: 50%;
      padding-left: 8%;
      @media @screen767 {
        width: 100%;
        padding: 0 16px;
      }
      &__wrapper {
        max-width: 504px;
        width: 100%;
        gap: 16px;
        @media @screen767 {
          max-width: 100%;
        }
      }
    }
  }
  .direct-tax-notice-board-feature,
  .indirect-tax-notice-board-feature {
    align-items: center;
    justify-content: center;
    justify-items: center;

    .cards-container {
      display: flex;
      flex-wrap: wrap;
      justify-self: center;
      gap: 24px;
      .ant-card {
        max-width: 234px;
      }
      .card-content {
        width: 240px;
        p {
          font-size: 16px;
        }
        .ant-btn {
          font-size: 16px;
          padding: 0px;
        }
        h4 {
          margin-bottom: 0;
        }
        svg {
          min-height: 65px;
        }
      }
    }
  }
  .how-it-work-section {
    @media @screen767 {
      padding: 16px 16px;
    }
    .work-content {
      @media @screen1024 {
        gap: 16px;
      }
      @media @screen767 {
        gap: 8px;
        padding: 16px 16px;
      }
      p {
        font-size: 20px;
        @media @screen1024 {
          font-size: 16px;
        }
        @media @screen767 {
          font-size: 14px;
        }
        @media @screen414 {
          font-size: 12px;
        }
      }
      svg {
        min-height: 61px;
        @media @screen640 {
          width: 50px;
        }
        @media @screen414 {
          width: 30px;
        }
      }
    }
    .how-it-work-content {
      @media @screen1024 {
        max-width: 900;
        overflow: scroll;
      }
    }
    img {
      width: 100%;
      align-content: center;
      @media @screen1024 {
        display: none;
      }
    }
  }
  .about-us-section {
    .testimonial-carousel {
      width: 100%;
      display: block;
      .slick-list {
        margin: 0 -22px;
        @media @screen1024 {
          margin: 0 -16px;
        }
        @media @screen767 {
          padding: 0 8px;
        }
        .slick-track {
          display: flex;
          .slick-slide {
            height: auto;
            & > div {
              height: 100%;
              .ant-card {
                height: 100%;
              }
            }
          }
        }
      }
      .slick-dots {
        bottom: 0;
        position: relative;
        margin-top: 16px;
        li {
          button {
            background: var(--breadcrumbLink);
          }
        }
        .slick-active {
          button {
            background: var(--system-Links);
          }
        }
      }
      .slick-slide {
        padding: 0 22px;
        @media @screen1024 {
          padding: 0 16px;
        }
        @media @screen767 {
          padding: 0 8px;
        }
        .ant-card {
          .ant-avatar {
            background: @primary-primary-tint-1;
            padding: 4px;
            img {
              width: auto;
              height: auto;
            }
          }
        }
      }
      .slick-arrow {
        height: 50px;
        width: 50px;
        background-color: var(--background);
        background-image: url('../assets/images/vector.png');
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        z-index: 1;
        &.slick-disabled {
          opacity: 0;
        }
        &.slick-prev {
          transform: scale(-1);
        }
      }
    }
  }
  .footer-section {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('../assets/images/rectangle-background.png');

    .content {
      @media @screen1024 {
        flex-direction: column;
        align-items: center;
        gap: 24px;
      }
      .text-section {
        @media @screen1024 {
          align-items: center;
        }
      }
    }
    .cards-container {
      flex: 1 1 50%;
      max-width: 50%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      gap: 16px;
      .ant-card {
        max-width: 234px;
      }
      @media @screen767 {
        flex: 1 1 calc(100% - 16px);
        justify-content: center;
        box-sizing: border-box;
        max-width: 100%;
      }
      @media @screen640 {
        flex: 1 1 calc(100% - 16px);
        justify-content: center;
        box-sizing: border-box;
        max-width: 100%;
        flex-flow: column;
        align-items: center;
      }
      .card-content {
        flex: 1 1 calc(50% - 16px);
        p {
          font-size: 12px;
        }
        .ant-btn {
          font-size: 16px;
          padding: 0px;
        }
        .know-more-btn {
          color: @primary-primary-shade-1;
        }
      }
    }
  }
}
.ant-rate {
  color: @system-Links;
  font-size: 14px;
}
