#root {
  font-family: 'Poppins';
}
.ant-layout {
  min-height: 100vh;
}
/********** margin padding function start here **************/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.gap-0 {
  gap: 0px;
}
.p-2 {
  padding: 2px;
}
.pt-2 {
  padding-top: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-2 {
  padding-right: 2px;
}
.pb-2 {
  padding-bottom: 2px;
}
.m-2 {
  margin: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.gap-2 {
  gap: 2px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.gap-4 {
  gap: 4px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.gap-8 {
  gap: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.gap-16 {
  gap: 16px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.gap-20 {
  gap: 20px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.gap-24 {
  gap: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.gap-32 {
  gap: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.gap-40 {
  gap: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.gap-48 {
  gap: 48px;
}
.p-64 {
  padding: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.m-64 {
  margin: 64px;
}
.mt-64 {
  margin-top: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.gap-64 {
  gap: 64px;
}
.p-12 {
  padding: 12px;
}
.pt-12 {
  padding-top: 12px;
}
.pl-12 {
  padding-left: 12px;
}
.pr-12 {
  padding-right: 12px;
}
.pb-12 {
  padding-bottom: 12px;
}
.m-12 {
  margin: 12px;
}
.mt-12 {
  margin-top: 12px;
}
.ml-12 {
  margin-left: 12px;
}
.mr-12 {
  margin-right: 12px;
}
.mb-12 {
  margin-bottom: 12px;
}
.gap-12 {
  gap: 12px;
}
.p-14 {
  padding: 14px;
}
.pt-14 {
  padding-top: 14px;
}
.pl-14 {
  padding-left: 14px;
}
.pr-14 {
  padding-right: 14px;
}
.pb-14 {
  padding-bottom: 14px;
}
.m-14 {
  margin: 14px;
}
.mt-14 {
  margin-top: 14px;
}
.ml-14 {
  margin-left: 14px;
}
.mr-14 {
  margin-right: 14px;
}
.mb-14 {
  margin-bottom: 14px;
}
.gap-14 {
  gap: 14px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.gap-16 {
  gap: 16px;
}
.p-18 {
  padding: 18px;
}
.pt-18 {
  padding-top: 18px;
}
.pl-18 {
  padding-left: 18px;
}
.pr-18 {
  padding-right: 18px;
}
.pb-18 {
  padding-bottom: 18px;
}
.m-18 {
  margin: 18px;
}
.mt-18 {
  margin-top: 18px;
}
.ml-18 {
  margin-left: 18px;
}
.mr-18 {
  margin-right: 18px;
}
.mb-18 {
  margin-bottom: 18px;
}
.gap-18 {
  gap: 18px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.gap-20 {
  gap: 20px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.gap-24 {
  gap: 24px;
}
.p-28 {
  padding: 28px;
}
.pt-28 {
  padding-top: 28px;
}
.pl-28 {
  padding-left: 28px;
}
.pr-28 {
  padding-right: 28px;
}
.pb-28 {
  padding-bottom: 28px;
}
.m-28 {
  margin: 28px;
}
.mt-28 {
  margin-top: 28px;
}
.ml-28 {
  margin-left: 28px;
}
.mr-28 {
  margin-right: 28px;
}
.mb-28 {
  margin-bottom: 28px;
}
.gap-28 {
  gap: 28px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.gap-32 {
  gap: 32px;
}
.p-36 {
  padding: 36px;
}
.pt-36 {
  padding-top: 36px;
}
.pl-36 {
  padding-left: 36px;
}
.pr-36 {
  padding-right: 36px;
}
.pb-36 {
  padding-bottom: 36px;
}
.m-36 {
  margin: 36px;
}
.mt-36 {
  margin-top: 36px;
}
.ml-36 {
  margin-left: 36px;
}
.mr-36 {
  margin-right: 36px;
}
.mb-36 {
  margin-bottom: 36px;
}
.gap-36 {
  gap: 36px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.gap-40 {
  gap: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.gap-48 {
  gap: 48px;
}
/********** margin padding function end here **************/
.m-hide {
  display: block;
}
@media only screen and (max-width: 767px) {
  .m-hide {
    display: none;
  }
}
.d-hide {
  display: none;
}
@media only screen and (max-width: 767px) {
  .d-hide {
    display: block;
  }
}
.pointer {
  cursor: pointer;
}
.pointer-not-allowed {
  cursor: not-allowed;
}
.text {
  cursor: text;
}
.grab {
  cursor: grab;
}
/********** text alignment start here **************/
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-end {
  text-align: end;
}
/********** text alignment end here **************/
/********** flex box class start here **************/
.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.d-grid {
  display: -webkit-box;
  display: -ms-grid;
  display: grid;
}
.flex-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
}
.flex-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}
.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}
.justify-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.justify-around {
  -ms-flex-pack: distribute;
  justify-content: space-around;
}
.align-center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.align-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.align-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.align-self-stretch {
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.flex-grow-1 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.flex-grow-2 {
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
}
.flex-shrink-0 {
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.flex-shrink-1 {
  -ms-flex-negative: 1;
  flex-shrink: 1;
}
.flex-shrink-2 {
  -ms-flex-negative: 2;
  flex-shrink: 2;
}
.flex-wrap {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.align-self-end {
  -ms-flex-item-align: end;
  align-self: flex-end;
}
.flex-1 {
  flex: 1;
}
.flex-direction-column {
  flex-direction: column;
}
.align-self-center {
  align-self: center;
}
/********** flex box class end here **************/
.width-percent-20 {
  width: 20%;
}
.width-percent-50 {
  width: 50%;
}
.width-percent-60 {
  width: 60%;
}
.width-percent-80 {
  width: 80%;
}
.width-percent-40 {
  width: 40%;
}
.width-percent-5 {
  width: 5%;
}
.width-percent-25 {
  width: 25%;
}
.full-width {
  width: 100%;
}
.max-width-164 {
  max-width: 164px;
}
.max-width-430 {
  max-width: 430px;
}
.full-height {
  height: 100%;
}
.calendar-height {
  height: 260px;
}
.site-result-demo-error-icon {
  color: #ff4d4f;
}
.b-0 {
  border: none;
}
/*position class start here*/
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.fixed {
  position: fixed;
}
.sticky {
  position: sticky;
}
/*position class end here*/
.p-0 {
  padding: 0px;
}
.pt-0 {
  padding-top: 0px;
}
.pl-0 {
  padding-left: 0px;
}
.pr-0 {
  padding-right: 0px;
}
.pb-0 {
  padding-bottom: 0px;
}
.m-0 {
  margin: 0px;
}
.mt-0 {
  margin-top: 0px;
}
.ml-0 {
  margin-left: 0px;
}
.mr-0 {
  margin-right: 0px;
}
.mb-0 {
  margin-bottom: 0px;
}
.gap-0 {
  gap: 0px;
}
.fs-0 {
  font-size: 0px;
}
.p-2 {
  padding: 2px;
}
.pt-2 {
  padding-top: 2px;
}
.pl-2 {
  padding-left: 2px;
}
.pr-2 {
  padding-right: 2px;
}
.pb-2 {
  padding-bottom: 2px;
}
.m-2 {
  margin: 2px;
}
.mt-2 {
  margin-top: 2px;
}
.ml-2 {
  margin-left: 2px;
}
.mr-2 {
  margin-right: 2px;
}
.mb-2 {
  margin-bottom: 2px;
}
.gap-2 {
  gap: 2px;
}
.fs-2 {
  font-size: 2px;
}
.p-4 {
  padding: 4px;
}
.pt-4 {
  padding-top: 4px;
}
.pl-4 {
  padding-left: 4px;
}
.pr-4 {
  padding-right: 4px;
}
.pb-4 {
  padding-bottom: 4px;
}
.m-4 {
  margin: 4px;
}
.mt-4 {
  margin-top: 4px;
}
.ml-4 {
  margin-left: 4px;
}
.mr-4 {
  margin-right: 4px;
}
.mb-4 {
  margin-bottom: 4px;
}
.gap-4 {
  gap: 4px;
}
.fs-4 {
  font-size: 4px;
}
.p-8 {
  padding: 8px;
}
.pt-8 {
  padding-top: 8px;
}
.pl-8 {
  padding-left: 8px;
}
.pr-8 {
  padding-right: 8px;
}
.pb-8 {
  padding-bottom: 8px;
}
.m-8 {
  margin: 8px;
}
.mt-8 {
  margin-top: 8px;
}
.ml-8 {
  margin-left: 8px;
}
.mr-8 {
  margin-right: 8px;
}
.mb-8 {
  margin-bottom: 8px;
}
.gap-8 {
  gap: 8px;
}
.fs-8 {
  font-size: 8px;
}
.p-16 {
  padding: 16px;
}
.pt-16 {
  padding-top: 16px;
}
.pl-16 {
  padding-left: 16px;
}
.pr-16 {
  padding-right: 16px;
}
.pb-16 {
  padding-bottom: 16px;
}
.m-16 {
  margin: 16px;
}
.mt-16 {
  margin-top: 16px;
}
.ml-16 {
  margin-left: 16px;
}
.mr-16 {
  margin-right: 16px;
}
.mb-16 {
  margin-bottom: 16px;
}
.gap-16 {
  gap: 16px;
}
.fs-16 {
  font-size: 16px;
}
.p-20 {
  padding: 20px;
}
.pt-20 {
  padding-top: 20px;
}
.pl-20 {
  padding-left: 20px;
}
.pr-20 {
  padding-right: 20px;
}
.pb-20 {
  padding-bottom: 20px;
}
.m-20 {
  margin: 20px;
}
.mt-20 {
  margin-top: 20px;
}
.ml-20 {
  margin-left: 20px;
}
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.gap-20 {
  gap: 20px;
}
.fs-20 {
  font-size: 20px;
}
.p-24 {
  padding: 24px;
}
.pt-24 {
  padding-top: 24px;
}
.pl-24 {
  padding-left: 24px;
}
.pr-24 {
  padding-right: 24px;
}
.pb-24 {
  padding-bottom: 24px;
}
.m-24 {
  margin: 24px;
}
.mt-24 {
  margin-top: 24px;
}
.ml-24 {
  margin-left: 24px;
}
.mr-24 {
  margin-right: 24px;
}
.mb-24 {
  margin-bottom: 24px;
}
.gap-24 {
  gap: 24px;
}
.fs-24 {
  font-size: 24px;
}
.p-32 {
  padding: 32px;
}
.pt-32 {
  padding-top: 32px;
}
.pl-32 {
  padding-left: 32px;
}
.pr-32 {
  padding-right: 32px;
}
.pb-32 {
  padding-bottom: 32px;
}
.m-32 {
  margin: 32px;
}
.mt-32 {
  margin-top: 32px;
}
.ml-32 {
  margin-left: 32px;
}
.mr-32 {
  margin-right: 32px;
}
.mb-32 {
  margin-bottom: 32px;
}
.gap-32 {
  gap: 32px;
}
.fs-32 {
  font-size: 32px;
}
.p-40 {
  padding: 40px;
}
.pt-40 {
  padding-top: 40px;
}
.pl-40 {
  padding-left: 40px;
}
.pr-40 {
  padding-right: 40px;
}
.pb-40 {
  padding-bottom: 40px;
}
.m-40 {
  margin: 40px;
}
.mt-40 {
  margin-top: 40px;
}
.ml-40 {
  margin-left: 40px;
}
.mr-40 {
  margin-right: 40px;
}
.mb-40 {
  margin-bottom: 40px;
}
.gap-40 {
  gap: 40px;
}
.fs-40 {
  font-size: 40px;
}
.p-48 {
  padding: 48px;
}
.pt-48 {
  padding-top: 48px;
}
.pl-48 {
  padding-left: 48px;
}
.pr-48 {
  padding-right: 48px;
}
.pb-48 {
  padding-bottom: 48px;
}
.m-48 {
  margin: 48px;
}
.mt-48 {
  margin-top: 48px;
}
.ml-48 {
  margin-left: 48px;
}
.mr-48 {
  margin-right: 48px;
}
.mb-48 {
  margin-bottom: 48px;
}
.gap-48 {
  gap: 48px;
}
.fs-48 {
  font-size: 48px;
}
.p-64 {
  padding: 64px;
}
.pt-64 {
  padding-top: 64px;
}
.pl-64 {
  padding-left: 64px;
}
.pr-64 {
  padding-right: 64px;
}
.pb-64 {
  padding-bottom: 64px;
}
.m-64 {
  margin: 64px;
}
.mt-64 {
  margin-top: 64px;
}
.ml-64 {
  margin-left: 64px;
}
.mr-64 {
  margin-right: 64px;
}
.mb-64 {
  margin-bottom: 64px;
}
.gap-64 {
  gap: 64px;
}
.fs-64 {
  font-size: 64px;
}
.float-right {
  float: right;
}
@media only screen and (max-width: 767px) {
  .ant-layout-footer {
    padding: 16px 50px;
  }
}
/* width */
::-webkit-scrollbar,
::-webkit-scrollbar-thumb {
  width: 8px;
  background-clip: padding-box;
  color: transparent;
  border-left: 0 solid transparent;
  border-radius: 8px;
}
::-webkit-scrollbar-thumb {
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
:hover::-webkit-scrollbar-thumb {
  border-left: 1px solid rgba(0, 0, 0, 0.3);
  background-clip: padding-box;
  -webkit-box-shadow: inset 0 0 0 8px;
  box-shadow: inset 0 0 0 8px;
}
.mobile-restriction-screen {
  height: calc(100vh - 72px);
}
.mobile-restriction-screen p {
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
  color: var(--text-medium);
  margin: 0px;
}
.ant-table-wrapper .ant-table-content {
  scrollbar-width: thin;
  scrollbar-color: #eaeaea transparent;
}
.ant-table-wrapper .ant-table {
  overflow: auto;
  border: 1px solid var(--stroke-dark);
  border-radius: 8px;
}
.ant-table-wrapper .ant-table-thead > tr > th {
  padding: 12px 16px;
}
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 16px;
  font-size: 12px;
  font-style: normal;
  line-height: 18px;
  color: var(--textDark);
}
.ant-col {
  padding: 0px !important;
}
.table-card .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  width: 36px;
}
.text-size-12 {
  font-size: 12px;
}
.common-search-input.ant-input-affix-wrapper {
  width: 256px;
  padding: 8px 12px;
  border-radius: 8px;
  border: 1px solid var(--Neutral-02);
  background: var(--Background-Background_01);
  color: #777777;
}
.common-search-input.ant-input-affix-wrapper:hover {
  border: 1px solid var(--primary-Main);
}
.common-search-input.ant-input-affix-wrapper.width-full {
  width: 100%;
}
.multi-pdf {
  max-width: 164px;
  word-break: break-all;
  display: inline-block;
}
.rangePicker {
  min-width: 232px;
}
.stroke-light-border {
  border: 1px solid var(--stroke-light);
  border-radius: 8px;
}
.capitalize-first {
  text-transform: lowercase;
}
.capitalize-first::first-letter {
  text-transform: uppercase;
}
.ant-popover > .ant-popover-arrow {
  display: none;
}
.disconnect-btn {
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  cursor: pointer;
}
.gst-tabs .ant-tabs-nav {
  margin-bottom: 32px;
}
.gst-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list {
  width: 100%;
}
.gst-tabs .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-nav-list .ant-tabs-tab {
  flex: 1;
  justify-content: center;
  width: auto;
}
.rotate {
  -webkit-animation: rotate 5s normal linear infinite;
  animation: rotate 5s normal linear infinite;
  display: inline-block;
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate3d(0, 0, 1, 360deg);
    transform: rotate3d(0, 0, 1, 360deg);
  }
  25% {
    -webkit-transform: rotate3d(0, 0, 1, 270deg);
    transform: rotate3d(0, 0, 1, 270deg);
  }
  50% {
    -webkit-transform: rotate3d(0, 0, 1, 180deg);
    transform: rotate3d(0, 0, 1, 180deg);
  }
  75% {
    -webkit-transform: rotate3d(0, 0, 1, 90deg);
    transform: rotate3d(0, 0, 1, 90deg);
  }
  100% {
    -webkit-transform: rotate3d(0, 0, 1, 0deg);
    transform: rotate3d(0, 0, 1, 0deg);
  }
}
:root {
  --color-white: #ffffff;
  --color-black: #000000;
  --primary-Main: #7a2976;
  --primary-Tint-01: #d966d3;
  --primary-Tint-02: #f2ccf0;
  --primary-Shade-01: #4d0049;
  --primary-Shade-02: #260024;
  --color-grey-light: #f2f2f2;
  --background-1: #ffffff;
  --background-2: #fbf9fb;
  --background: #f2f4f7;
  --breadcrumbLink: #8c8c8c;
  --breadcrumbLinkActive: #141414;
  --textDark: #161616;
  --character-primary-85: #000000d9;
  --text-medium: #777777;
  --stroke-dark: #d6d6d6;
  --stroke-light: #e7e7e7;
  --Neutral-02: #e6e6e6;
  --Neutral-08: #4c4c4c;
  --Background-Background_01: #fff;
  --background-ai: #192642;
  --border-ai: #0051ff;
  --border-calendar: #1890ff;
  --primary-primary-shade-1: #004373;
  --system-orange: #e64e2c;
  --shadow-color: #bdb6b6;
  --font-family: 'Poppins', sans-serif;
  --green: #04724d;
  --tint-green: #ccf2e5;
  --system-green: #3bb665;
  --system-Success-fill: #f6ffed;
  --system-Links: #006fc0;
  --primary-primary-tint-1: #cce2f2;
  --system-red: #e64e2c;
  --system-error-fill: #fff1f0;
  --system-warning: #fa8c16;
  --system-warning-fill: #fffbe6;
}
/*breakpoint mixin start here*/
/*breakpoint mixin end here*/
*,
:before,
:after {
  box-sizing: border-box;
}
body.indirect-tax {
  --primary-Main: #04724d;
}
/*container start here*/
.container {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  padding: 0 32px;
}
@media only screen and (max-width: 1260px) {
  .container {
    padding: 0 24px;
  }
}
@media only screen and (max-width: 1170px) {
  .container {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 767px) {
  .container {
    padding: 0 16px;
  }
}
.container-fluid {
  width: 100%;
  margin: auto;
  padding: 0 56px;
}
@media only screen and (max-width: 1170px) {
  .container-fluid {
    padding: 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .container-fluid {
    padding: 0 16px;
  }
}
/*container end here*/
.height-x-header {
  min-height: calc(100vh - 232px);
}
.bg-background2 {
  background-color: var(--background);
}
.medium-text-color {
  color: var(--text-medium);
}
.system-red-color {
  color: var(--system-red);
}
.color-white {
  color: var(--color-white);
}
.color-main {
  color: var(--primary-Main);
}
.notice-due-date {
  color: var(--system-red);
}
.color-blue {
  color: var(--system-Links);
}
.color-red {
  color: var(--system-red);
}
.color-orange {
  color: var(--system-warning);
}
.bg-blue {
  background-color: var(--system-Links);
}
.bg-red {
  background-color: var(--system-red);
}
.bg-orange {
  background-color: var(--system-warning);
}
.bg-background {
  background-color: var(--background);
}
.status-form-verified {
  background-color: var(--primary-primary-tint-1);
  color: var(--system-Links);
}
.status-for-verification {
  background-color: var(--system-Success-fill);
  color: var(--system-green);
}
.status-failed {
  background-color: var(--system-error-fill);
  color: var(--system-red);
}
.status-success {
  background-color: var(--system-Success-fill);
  color: var(--system-green);
}
.status-pending {
  background-color: var(--system-warning-fill);
  color: var(--system-warning);
}
.status-closed {
  background-color: var(--system-error-fill);
  color: var(--system-red);
}
.status-replied {
  background-color: var(--system-Success-fill);
  color: var(--system-green);
}
.status-default {
  background-color: var(--text-medium);
}
.bg-warning {
  background-color: var(--system-warning);
}
.bg-blue {
  background-color: var(--system-Links);
}
.bg-orange {
  background-color: var(--system-orange);
}
.text-white {
  color: var(--color-white);
}
.dark-text {
  color: var(--textDark);
}
.text-medium {
  color: var(--text-medium);
}
header {
  position: sticky;
  top: 0;
  z-index: 9;
  border-bottom: 1px solid var(--stroke-dark);
  height: 72px;
  background: var(--color-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
}
header .header-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 24px;
  width: 100%;
}
@media only screen and (max-width: 1460px) {
  header .header-wrapper {
    gap: 16px;
  }
}
@media only screen and (max-width: 1170px) {
  header .header-wrapper {
    gap: 12px;
  }
}
header .header-wrapper .logo {
  font-weight: 600;
  font-size: 20px;
  cursor: pointer;
  display: flex;
}
@media only screen and (max-width: 375px) {
  header .header-wrapper .logo svg {
    height: 25px;
    width: auto;
  }
}
header .header-wrapper .header-left {
  height: 64px;
  flex: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
header .header-wrapper .header-left .menu-list {
  width: 100%;
}
header .header-wrapper .header-left .menu-list nav {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  gap: 24px;
}
@media only screen and (max-width: 1170px) {
  header .header-wrapper .header-left .menu-list nav {
    gap: 12px;
  }
}
header .header-wrapper .header-left .menu-list nav li {
  height: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  position: relative;
}
@media only screen and (max-width: 1460px) {
  header .header-wrapper .header-left .menu-list nav li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }
}
header .header-wrapper .header-left .menu-list nav li.active-tab .item {
  color: var(--primary-Main);
  font-weight: 500;
}
header .header-wrapper .header-left .menu-list nav li.active-tab::before {
  content: '';
  position: absolute;
  bottom: -1px;
  background: '';
  height: 4px;
  width: 100%;
}
header .header-wrapper .header-left .menu-list nav li a {
  line-height: 24px;
  text-decoration: none;
  color: var(--color-black);
  display: block;
}
header .header-wrapper .header-left .menu-list nav li a svg {
  display: block;
}
@media only screen and (max-width: 1024px) {
  header .header-wrapper .header-left .menu-list nav li a svg {
    height: 30px;
    width: auto;
  }
}
header .header-wrapper .mobile-menu-list {
  position: fixed;
  background-color: var(--color-white);
  width: 100%;
  left: 0;
  top: 54px;
  overflow-y: auto;
  opacity: 0;
  visibility: hidden;
  height: auto;
  max-height: calc(100% - 54px);
  overflow: auto;
  transition: all 0.3s ease-in-out;
}
header .header-wrapper .mobile-menu-list ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  flex-direction: column;
  height: 100%;
  row-gap: 12px;
  font-size: 16px;
  list-style: none;
  margin: 0;
}
header .header-wrapper .mobile-menu-list ul li {
  padding: 8px;
}
header .header-wrapper .mobile-menu-list ul li a,
header .header-wrapper .mobile-menu-list ul li .sign-out-link {
  width: 100%;
  cursor: pointer;
}
header .header-wrapper .mobile-menu-list ul li a:hover,
header .header-wrapper .mobile-menu-list ul li .sign-out-link:hover {
  color: var(--primary-Main);
}
header .header-wrapper .mobile-menu-list ul li.active-tab .item {
  color: var(--primary-Main);
  font-weight: 500;
}
header .header-wrapper .mobile-menu-list p {
  padding: 8px;
}
header .header-wrapper .mobile-menu-list.open {
  opacity: 1;
  visibility: visible;
}
header .header-wrapper .mobile-menu-list .ant-collapse-header {
  flex-direction: row-reverse;
  align-items: center;
  gap: 12px;
  width: fit-content;
  padding: 8px;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
}
header .header-wrapper .mobile-menu-list .ant-collapse {
  border-radius: 0;
}
header .header-wrapper .mobile-menu-list .ant-collapse-item {
  background-color: var(--background-2);
}
header .header-wrapper .mobile-menu-list .ant-collapse-content-box {
  padding: 0;
}
header .header-wrapper .mobile-menu-list .menu-sub-list-items {
  row-gap: 0;
}
header .header-wrapper .header-right .ant-avatar {
  width: 48px;
  height: 48px;
  border-radius: 48px;
}
@media only screen and (max-width: 1024px) {
  header .header-wrapper .header-right {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    gap: 24px;
  }
}
header .header-wrapper .header-right ul {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  gap: 16px;
}
@media only screen and (max-width: 1460px) {
  header .header-wrapper .header-right ul {
    gap: 16px;
  }
}
header .header-wrapper .header-right ul li svg {
  width: 24px;
}
header .header-wrapper .header-right ul li a {
  display: inline;
}
header .header-wrapper img {
  max-height: 100%;
}
header .header-wrapper .menu-list-items {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  height: 100%;
  gap: 24px;
}
@media only screen and (max-width: 1460px) {
  header .header-wrapper .menu-list-items {
    gap: 16px;
  }
}
@media only screen and (max-width: 1170px) {
  header .header-wrapper .menu-list-items {
    gap: 12px;
  }
}
header .header-wrapper .menu-list-section {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
header .btn-wrapper .m-hide {
  display: block;
}
@media only screen and (max-width: 1024px) {
  header .btn-wrapper .m-hide {
    display: none;
  }
}
header .m-hide {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media only screen and (max-width: 1024px) {
  header .m-hide {
    display: none;
  }
}
header .d-hide {
  display: none;
}
@media only screen and (max-width: 1024px) {
  header .d-hide {
    display: block;
  }
}
.plan-notification {
  display: flex;
  padding: 10px 40px !important;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  background: var(--primary-primary-tint-1);
}
.plan-notification .ant-typography {
  color: var(--textDark);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
}
.plan-notification .ant-typography span {
  color: var(--textDark);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-decoration-line: underline;
}
.dashboard-content-holder {
  display: flex;
  flex-direction: column;
}
.dashboard-content-holder .dashboard-content-wrapper {
  gap: 24px;
}
.dashboard-content-holder .dashboard-content-wrapper .section-1 .ant-row .color-box {
  display: flex;
  width: 24px;
  height: 24px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-right: 9px;
}
.ant-divider-horizontal {
  margin: 0;
}
.statistic-cards {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.statistic-cards .statistic .value {
  color: var(--primary-Main);
}
.sync-calendar .ant-typography {
  color: var(--text-dark);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
@media only screen and (max-width: 1170px) {
  .sync-calendar .ant-flex {
    flex-wrap: wrap;
  }
}
.ant-color-picker-trigger {
  border: none;
}
.ant-card .ant-card-body {
  padding: 16px;
  width: 100%;
}
.color-blue {
  color: #006fc0;
}
.color-red {
  color: #e64e2c;
}
.color-orange {
  color: #fa8c16;
}
.bg-blue {
  background-color: #006fc0;
}
.bg-red {
  background-color: #e64e2c;
}
.bg-orange {
  background-color: #fa8c16;
}
.head {
  color: var(--character-primary-85);
}
.plans-container.ant-modal .ant-modal-content {
  padding: 32px;
  background: var(--background-2);
}
.plans-container.ant-modal .plans-content .plans-text {
  width: 800px;
  margin-top: 30px;
}
.plans-container.ant-modal .plans-content .plans-text .main-text {
  position: relative;
  width: max-content;
  margin: auto;
}
.plans-container.ant-modal .plans-content .plans-text .main-text .three-lines-svg {
  position: absolute;
  width: 57.229px;
  height: 36.266px;
  left: -11%;
  top: -28%;
}
.plans-container.ant-modal .plans-content .plans-text .main-text h1 {
  color: var(--primary-primary-shade-1);
  font-size: 32px;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 0px;
}
.plans-container.ant-modal .plans-content .plans-text .main-text .round-arrow {
  position: absolute;
  right: calc(-148px + 14.88px);
  bottom: 30%;
}
.plans-container.ant-modal .plans-content .plans-text p {
  margin: 0;
}
.plans-container.ant-modal .plans-content .plans-card-content .card {
  width: min-content;
}
.plans-container.ant-modal .plans-content .plans-card-content .card .title {
  color: var(--primary-Shade-01);
  font-size: 32px;
  font-weight: 700;
  margin: 0;
}
.plans-container.ant-modal .plans-content .plans-card-content .card .plans-duration {
  color: var(--text-medium);
}
.plans-container.ant-modal .plans-content .plans-card-content .card p {
  margin: 0;
  font-size: 12px;
  padding: 12px;
  border-radius: 8px;
  background: var(--background-2);
}
.plans-container.ant-modal .plans-content .plans-card-content .card .ant-btn {
  min-width: 190px;
}
.plans-container.ant-modal .plans-content .plans-card-content .card .btn-current-plan {
  position: absolute;
  right: 16px;
  top: -25px;
  gap: var(--Large-Radius, 8px);
  border-radius: 8px;
  border: 1.5px solid var(--primary-Main);
  background: var(--primary-Tint-02);
}
.plans-container.ant-modal .plans-content .plans-card-content .card .btn-current-plan.gst {
  border: 1.5px solid var(--green);
  background: var(--tint-green);
}
.plans-container .ant-modal .ant-modal-content {
  padding: 32px;
}
.active-plan {
  background: var(--primary-Main);
  color: var(--color-white);
}
.active-plan .card .price {
  color: var(--color-white) !important;
}
.active-plan .ant-btn {
  background: var(--color-white);
  color: var(--color-black);
}
.active-plan .ant-btn:hover {
  background: var(--color-white) !important;
  color: var(--color-black) !important;
}
.active-plan .card .plans-duration {
  color: var(--primary-Tint-02) !important;
}
.active-plan .card .plans-duration.gst {
  color: var(--tint-green) !important;
}
.button-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 16px;
}
.button-container .left-buttons {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.button-container .left-buttons .ant-btn {
  display: flex;
  align-items: center;
}
.button-container .right-buttons {
  display: flex;
  align-items: center;
}
.button-container .right-buttons .ant-btn {
  display: flex;
  align-items: center;
}
.button-container .right-buttons.left-buttons {
  flex-direction: column;
}
@media (max-width: 768px) {
  .button-container {
    flex-direction: column;
  }
  .button-container .left-buttons {
    width: 100%;
    margin-bottom: 8px;
    margin-top: 8px;
    flex-direction: column;
  }
  .button-container .left-buttons .ant-btn {
    width: 100%;
    margin-right: 0;
  }
}
.statistic-card {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}
.eye-action {
  width: 10%;
  display: flex;
  padding: 16px 0px;
  align-items: flex-end;
  justify-content: center;
}
.remove-modal .ant-modal-footer {
  gap: 8px;
}
.empty {
  margin-top: 50px;
  font-style: normal;
  line-height: 28px;
  color: var(--text-medium);
}
.notices-card .description {
  margin: 0;
}
@media only screen and (max-width: 1440px), only screen and (max-width: 1500px), only screen and (max-width: 1750px) {
  .filters .max-width {
    max-width: 200px;
  }
}
@media only screen and (max-width: 1080px), only screen and (max-width: 1260px), only screen and (max-width: 1350px) {
  .filters {
    flex-wrap: wrap;
  }
  .filters .max-width {
    max-width: 268px;
  }
}
.filters .ant-form-item {
  margin-bottom: 0;
}
.custom-active .filters {
  flex-wrap: wrap;
}
.custom-active .filters .ant-form-item {
  max-width: 255px;
}
.statistic {
  padding: 16px;
  align-items: center;
  flex: 1 1;
  gap: 16px;
  min-width: 196px;
  justify-content: flex-start;
}
.statistic:hover {
  border-radius: 8px;
  background: var(--background-2);
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
}
.icon {
  display: flex;
  width: 52px;
  height: 52px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--stroke-dark);
  background: var(--background-2);
}
.status {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
}
.status span {
  color: var(--Neutral-08);
}
.status .value {
  font-size: 28px;
  font-weight: 600;
  line-height: 34px;
}
.ant-btn-primary:disabled {
  opacity: 0.4;
}
.ant-btn-default:disabled {
  opacity: 0.4;
  background: none;
  color: var(--primary-Main);
  cursor: not-allowed !important;
}
.ant-btn-link:disabled {
  opacity: 0.4;
  color: var(--primary-Main);
}
a:hover {
  color: var(--system-Links);
}
.my-breadcrumb .ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.my-breadcrumb a {
  color: var(--breadcrumbLink);
  text-transform: capitalize;
}
.my-breadcrumb a.active-link {
  color: var(--textDark);
}
.heading {
  color: var(--primary-Main) !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.select {
  width: 268px;
}
.underline-btn > span {
  text-decoration: underline;
}
.title {
  color: var(--text-medium) !important;
}
.ask-ai-btn {
  border-radius: 16px;
  padding: 2px 4px;
  gap: 2px;
  height: auto;
}
.ask-ai-btn .ant-btn-icon {
  width: 10px;
  height: 10px;
  flex-shrink: 0;
  margin-inline-end: 2px !important;
}
.ask-ai-btn span {
  font-size: 8px;
  line-height: 10px;
}
.ask-ai-btn:hover {
  border-radius: 100px;
  border: 0.5px solid var(--border-ai);
  background: var(--background-ai) !important;
  box-shadow: 0px 0px 6px 0px rgba(0, 81, 255, 0.22);
  color: var(--color-white) !important;
}
.custom-pagination-wrapper {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.custom-pagination-wrapper ul li.ant-pagination-prev {
  margin-right: 16px;
}
.custom-pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-pagination .ant-pagination-next button,
.ant-pagination .ant-pagination-prev button {
  background: none;
}
.custom-pagination-button:hover {
  background-color: #f0f0f0;
}
.template-pagination .ant-pagination-item {
  border-radius: 8px;
}
.ant-row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}
.submission-card .check-icon {
  border-radius: 80px;
  border: 1px solid var(--stroke-dark);
  background: var(--Background-Background_02);
  width: 52px;
  height: 52px;
  padding: 12px;
  margin-right: 16px;
}
.submission-card .detail .items-title {
  font-size: 12px;
}
.submission-card .detail .date {
  color: var(--text-medium);
  font-size: 12px;
}
.submission-card .files-detail a,
.submission-card .files-detail .ant-btn > span {
  font-size: 12px;
}
.submission-card .files-detail .ant-btn {
  padding: 4px 12px;
}
.value {
  font-size: 16px;
}
.empty-text {
  width: 1168px;
  position: sticky;
  left: 0px;
  overflow: hidden;
  color: var(--text-dark);
  text-align: center;
  text-overflow: ellipsis;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  padding: 24px 16px;
}
.non-clickable {
  color: gray;
  pointer-events: none;
  text-transform: capitalize;
}
.h-512 .ant-modal-content {
  height: 512px;
}
.ant-table-wrapper .ant-table-expanded-row-fixed {
  padding: 24px 16px;
}
.ant-space-item {
  cursor: pointer;
}
.tick-gif {
  width: 128px;
  height: 128px;
}
.calendar-container .ant-picker-calendar .ant-picker-body {
  padding: 12px 8px;
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr td.ant-picker-cell.ant-picker-cell-in-view span {
  color: rgba(0, 0, 0, 0.85);
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr td.ant-picker-cell span {
  color: rgba(0, 0, 0, 0.25);
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr td > span {
  border: 1px solid transparent;
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr td.ant-picker-cell-today > span {
  border: 1px solid var(--border-calendar);
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr td.ant-picker-cell-selected > span {
  border: 1px solid var(--border-ai);
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr span.ant-badge.ant-badge-status.ant-badge-not-a-wrapper {
  display: flex;
  text-align: center;
  height: 24px;
  width: 24px;
  margin: auto;
  justify-content: center;
  padding: 1px !important;
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr span.ant-badge.ant-badge-status.ant-badge-not-a-wrapper .ant-badge-status-dot {
  display: none;
}
.calendar-container .ant-picker-calendar .ant-picker-body table tbody tr span.ant-badge.ant-badge-status.ant-badge-not-a-wrapper .ant-badge-status-text {
  margin-left: 0;
  font-size: 14px;
}
.ant-btn {
  padding: 0px 16px;
}
.ant-btn-sm {
  padding: 0px 7px !important;
}
.ant-btn.ask-ai-btn {
  padding: 2px 4px;
}
a {
  color: var(--system-Links);
}
.ant-form-item .ant-form-item-label > label {
  color: var(--textDark);
}
.table-card .ant-table-wrapper .ant-table-container table > thead > tr:first-child > *:first-child {
  width: 36px;
}
.track-return-status .tables .ant-table-wrapper {
  margin-top: 8px;
}
.track-return-status .tables .ant-table-body {
  scrollbar-width: thin;
  scrollbar-color: #eaeaea transparent;
  scrollbar-gutter: stable;
}
.track-return-status .tables .ant-typography {
  color: var(--text-medium);
  font-size: 16px;
  font-weight: 600;
}
.ant-collapse.table-collapse button {
  border-color: var(--stroke-dark);
}
.ant-collapse.table-collapse .title {
  font-weight: 600;
}
.ant-collapse.table-collapse .ant-collapse-item .ant-collapse-header {
  padding: 8px 0;
}
.ant-collapse.table-collapse .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
  padding: 8px 0 0 0;
}
.filter-form .ant-picker {
  min-width: 240px;
}
.logout-popup-btn {
  width: 100%;
  height: 52px;
  padding: 16px 32px 16px 32px;
  margin-top: 12px;
  gap: 6px;
  border-radius: 8px;
  background-color: var(--system-Links);
  font-family: var(--font-poppins);
  font-size: 20px;
  font-weight: 500;
  line-height: 20px;
  color: var(--color-white);
}
.notices-tabs .counts-tag {
  padding: 0px 6px;
  display: flex;
  align-items: center;
  border-radius: 48px;
  background: var(--Background-Background_01);
  box-shadow: -1px 2px 2px 0px rgba(0, 0, 0, 0.15);
}
.notices-tabs .counts-tag .ant-scroll-number {
  color: var(--text-dark);
}
.ant-modal .ant-modal-content {
  padding: 16px;
}
.ant-modal .ant-modal-content .ant-modal-header {
  margin-bottom: 24px;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item {
  margin-bottom: 24px;
}
.ant-modal .ant-modal-content .ant-modal-body .ant-form .ant-form-item .ant-btn {
  margin-bottom: 0;
}
.ant-modal .ant-modal-content .ant-modal-footer {
  flex-direction: row-reverse;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 0px;
}
.ant-modal .ant-modal-content .ant-modal-footer .ant-btn {
  width: 100%;
  margin: 0;
}
.ant-modal .ant-modal-title {
  color: var(--textDark);
}
.attachment-modal .ant-modal-body {
  height: 430px;
  overflow: auto;
}
.noticeboard-slider {
  padding: 40px 0;
}
.noticeboard-slider .slick-dots li button {
  background: #37455f;
}
.noticeboard-slider .slick-dots li.slick-active button {
  background: var(--system-Links);
}
.noticeboard-slider .notice-board .tax-category-judge.notice-board-redirect-section {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}
@media only screen and (max-width: 991px) {
  .noticeboard-slider .notice-board .notice-board-container {
    gap: 24px;
  }
}
@media only screen and (max-width: 767px) {
  .noticeboard-slider .notice-board .notice-board-container {
    flex-direction: column;
    padding-bottom: 20px;
  }
}
.noticeboard-slider .notice-board .notice-board-container .vector {
  top: 0;
  left: 239px;
  position: absolute;
  width: 119.277px;
  height: 111.976px;
  transform: rotate(44.995deg);
}
@media only screen and (max-width: 767px) {
  .noticeboard-slider .notice-board .notice-board-container .vector {
    display: none;
  }
}
.noticeboard-slider .notice-board .notice-board-container .calendar {
  max-width: 519px;
  width: 100%;
}
.noticeboard-slider .notice-board .notice-board-container .calendar img {
  width: 100%;
  display: block;
}
.noticeboard-slider .notice-board .notice-board-container .text-medium {
  color: var(--text-medium);
}
.noticeboard-slider .notice-board .notice-board-container .text-dark {
  color: var(--textDark);
}
.noticeboard-slider .notice-board .notice-board-container h2.semibold {
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .noticeboard-slider .notice-board .notice-board-container h2.semibold {
    font-size: 28px;
    line-height: 40px;
  }
}
.noticeboard-slider .notice-board .notice-board-container h4.medium {
  font-size: 20px;
  line-height: 30px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
@media only screen and (max-width: 767px) {
  .noticeboard-slider .notice-board .notice-board-container h4.medium {
    font-size: 16px;
    line-height: 24px;
  }
}
.notice-board-feature {
  background: var(--primary-primary-shade-1);
  padding: 80px 0;
  color: var(--Background-Background_01);
}
@media only screen and (max-width: 991px) {
  .notice-board-feature {
    padding: 60px 0;
  }
}
.notice-board-feature h2 {
  text-align: center;
  font-size: 34px;
  line-height: 44px;
  font-style: normal;
  font-weight: 600;
  margin: 0;
  margin-bottom: 60px;
}
@media only screen and (max-width: 767px) {
  .notice-board-feature h2 {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 40px;
  }
}
.notice-board-feature .feature-card {
  display: flex;
  gap: 16px;
}
.notice-board-feature .feature-card:not(:last-child) {
  margin-bottom: 16px;
}
.notice-board-feature .feature-card .icon {
  height: 32px;
  width: 32px;
  background: #fff;
  border-radius: 6px;
  flex-shrink: 0;
}
.notice-board-feature .feature-card h4 {
  font-size: 22px;
  line-height: 28px;
  font-style: normal;
  font-weight: 500;
  margin: 0;
}
.notice-board-feature .feature-card p {
  margin-top: 0;
}
footer {
  background: #fff;
  padding: 40px 0;
}
footer .footer-top-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
}
@media only screen and (max-width: 991px) {
  footer .footer-top-wrapper {
    flex-direction: column;
    text-align: left;
    align-items: flex-start;
  }
}
@media only screen and (max-width: 991px) {
  footer .footer-top-wrapper .footer-top-left {
    text-align: left;
  }
}
footer .footer-top-wrapper .footer-top-left span {
  display: inline-block;
}
footer .footer-top-wrapper .footer-top-left p {
  margin: 0;
}
footer .footer-top-wrapper .footer-top-left a {
  display: flex;
  align-items: center;
  gap: 4px;
  color: var(--textDark);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  margin: 4px 0;
}
@media only screen and (max-width: 991px) {
  footer .footer-top-wrapper .footer-top-right {
    margin-top: 20px;
  }
}
footer .footer-top-wrapper .footer-top-right ul {
  display: flex;
  align-items: center;
  gap: 32px;
  list-style: none;
  padding: 0;
  margin: 0;
}
@media only screen and (max-width: 991px) {
  footer .footer-top-wrapper .footer-top-right ul {
    gap: 16px;
  }
}
@media only screen and (max-width: 767px) {
  footer .footer-top-wrapper .footer-top-right ul {
    gap: 8px;
    flex-wrap: wrap;
  }
}
footer .footer-top-wrapper .footer-top-right ul li a {
  font-size: 16px;
  color: var(--textDark);
}
@media only screen and (max-width: 767px) {
  footer .footer-top-wrapper .footer-top-right ul li a {
    font-size: 14px;
  }
}
footer .footer-top-wrapper .footer-top-right ul li a:hover {
  color: var(--system-Links);
}
footer .logicwind-footer {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}
@media only screen and (max-width: 991px) {
  footer .logicwind-footer {
    flex-direction: column;
    gap: 4px;
    align-items: flex-start;
  }
}
.cms-page h2 {
  font-size: 22px;
  line-height: 28px;
}
.cms-page h5 {
  font-size: 18px;
  line-height: 24px;
}
.cms-page p {
  font-size: 16px;
}
.cms-page ul {
  padding-left: 20px;
}
.cms-page ul li {
  font-size: 16px;
}
.content-center {
  max-width: 1168px;
  margin: auto;
}
.btn-wrapper {
  display: flex;
  gap: 24px;
}
@media only screen and (max-width: 991px) {
  .btn-wrapper {
    gap: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .btn-wrapper {
    gap: 12px;
  }
}
.feature-wrapper {
  display: flex;
  align-items: center;
  gap: 40px;
}
@media only screen and (max-width: 991px) {
  .feature-wrapper {
    flex-direction: column;
    gap: 30px;
  }
}
.feature-wrapper .feature-banner {
  flex: 1;
}
.feature-wrapper .feature-banner img {
  width: 100%;
}
@media only screen and (max-width: 991px) {
  .feature-wrapper .feature-banner img {
    max-width: 600px;
    margin: auto;
  }
}
.feature-wrapper .feature-content {
  flex: 1;
}
.home-container .noticeboard-slider {
  width: 100%;
  background-repeat: no-repeat;
  background-image: url('../assets/images/hero-section.png');
  background-position: center;
}
.home-container .noticeboard-slider .hero-section .hero-title {
  font-size: 40px;
  font-weight: 900;
  color: var(--textDark);
  max-width: 248px;
  width: 100%;
  text-align: center;
  margin-bottom: 12px;
}
@media only screen and (max-width: 767px) {
  .home-container .noticeboard-slider .hero-section .hero-title {
    font-size: 32px;
  }
}
.home-container .noticeboard-slider .hero-section .hero-description {
  font-size: 20px;
  color: var(--text-medium);
  max-width: 479px;
  text-align: center;
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .home-container .noticeboard-slider .hero-section .hero-description {
    font-size: 16px;
  }
}
.home-container h1 {
  font-size: 32px;
  font-style: normal;
  font-weight: 900;
  line-height: 38px;
}
@media only screen and (max-width: 1024px) {
  .home-container h1 {
    font-size: 30px;
    line-height: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .home-container h1 {
    font-size: 24px;
    font-style: normal;
    line-height: 32px;
  }
}
.home-container h2 {
  font-size: 20px;
}
.home-container .direct-tax-notice-board-feature {
  background: linear-gradient(180deg, #ffffff 0%, #fff9ff 100%);
  padding-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .home-container .direct-tax-notice-board-feature {
    flex-direction: column-reverse;
  }
}
.home-container .direct-tax-notice-board-feature__image {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .home-container .direct-tax-notice-board-feature__image {
    width: 100%;
    padding: 0 16px;
  }
}
.home-container .direct-tax-notice-board-feature__image img {
  width: 100%;
  display: block;
}
.home-container .direct-tax-notice-board-feature .direct-tax-card-container {
  width: 50%;
  padding-right: 64px;
  padding-left: 24px;
}
@media only screen and (max-width: 767px) {
  .home-container .direct-tax-notice-board-feature .direct-tax-card-container {
    width: 100%;
    padding: 0 16px;
  }
}
.home-container .direct-tax-notice-board-feature .direct-tax-card-container__wrapper {
  max-width: 504px;
  width: 100%;
  margin: auto;
  gap: 16px;
}
@media only screen and (max-width: 767px) {
  .home-container .direct-tax-notice-board-feature .direct-tax-card-container__wrapper {
    max-width: 100%;
  }
}
.home-container .indirect-tax-notice-board-feature {
  background: linear-gradient(180deg, #ffffff 0%, #f3fffb 100%);
  padding-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .home-container .indirect-tax-notice-board-feature {
    flex-direction: column;
  }
}
.home-container .indirect-tax-notice-board-feature__image {
  width: 50%;
}
@media only screen and (max-width: 767px) {
  .home-container .indirect-tax-notice-board-feature__image {
    width: 100%;
    padding: 0 16px;
  }
}
.home-container .indirect-tax-notice-board-feature__image img {
  width: 100%;
  display: block;
}
.home-container .indirect-tax-notice-board-feature .gst-card-container {
  width: 50%;
  padding-left: 8%;
}
@media only screen and (max-width: 767px) {
  .home-container .indirect-tax-notice-board-feature .gst-card-container {
    width: 100%;
    padding: 0 16px;
  }
}
.home-container .indirect-tax-notice-board-feature .gst-card-container__wrapper {
  max-width: 504px;
  width: 100%;
  gap: 16px;
}
@media only screen and (max-width: 767px) {
  .home-container .indirect-tax-notice-board-feature .gst-card-container__wrapper {
    max-width: 100%;
  }
}
.home-container .direct-tax-notice-board-feature,
.home-container .indirect-tax-notice-board-feature {
  align-items: center;
  justify-content: center;
  justify-items: center;
}
.home-container .direct-tax-notice-board-feature .cards-container,
.home-container .indirect-tax-notice-board-feature .cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  gap: 24px;
}
.home-container .direct-tax-notice-board-feature .cards-container .ant-card,
.home-container .indirect-tax-notice-board-feature .cards-container .ant-card {
  max-width: 234px;
}
.home-container .direct-tax-notice-board-feature .cards-container .card-content,
.home-container .indirect-tax-notice-board-feature .cards-container .card-content {
  width: 240px;
}
.home-container .direct-tax-notice-board-feature .cards-container .card-content p,
.home-container .indirect-tax-notice-board-feature .cards-container .card-content p {
  font-size: 16px;
}
.home-container .direct-tax-notice-board-feature .cards-container .card-content .ant-btn,
.home-container .indirect-tax-notice-board-feature .cards-container .card-content .ant-btn {
  font-size: 16px;
  padding: 0px;
}
.home-container .direct-tax-notice-board-feature .cards-container .card-content h4,
.home-container .indirect-tax-notice-board-feature .cards-container .card-content h4 {
  margin-bottom: 0;
}
.home-container .direct-tax-notice-board-feature .cards-container .card-content svg,
.home-container .indirect-tax-notice-board-feature .cards-container .card-content svg {
  min-height: 65px;
}
@media only screen and (max-width: 767px) {
  .home-container .how-it-work-section {
    padding: 16px 16px;
  }
}
@media only screen and (max-width: 1024px) {
  .home-container .how-it-work-section .work-content {
    gap: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .home-container .how-it-work-section .work-content {
    gap: 8px;
    padding: 16px 16px;
  }
}
.home-container .how-it-work-section .work-content p {
  font-size: 20px;
}
@media only screen and (max-width: 1024px) {
  .home-container .how-it-work-section .work-content p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .home-container .how-it-work-section .work-content p {
    font-size: 14px;
  }
}
@media only screen and (max-width: 414px) {
  .home-container .how-it-work-section .work-content p {
    font-size: 12px;
  }
}
.home-container .how-it-work-section .work-content svg {
  min-height: 61px;
}
@media only screen and (max-width: 640px) {
  .home-container .how-it-work-section .work-content svg {
    width: 50px;
  }
}
@media only screen and (max-width: 414px) {
  .home-container .how-it-work-section .work-content svg {
    width: 30px;
  }
}
@media only screen and (max-width: 1024px) {
  .home-container .how-it-work-section .how-it-work-content {
    max-width: 900;
    overflow: scroll;
  }
}
.home-container .how-it-work-section img {
  width: 100%;
  align-content: center;
}
@media only screen and (max-width: 1024px) {
  .home-container .how-it-work-section img {
    display: none;
  }
}
.home-container .about-us-section .testimonial-carousel {
  width: 100%;
  display: block;
}
.home-container .about-us-section .testimonial-carousel .slick-list {
  margin: 0 -22px;
}
@media only screen and (max-width: 1024px) {
  .home-container .about-us-section .testimonial-carousel .slick-list {
    margin: 0 -16px;
  }
}
@media only screen and (max-width: 767px) {
  .home-container .about-us-section .testimonial-carousel .slick-list {
    padding: 0 8px;
  }
}
.home-container .about-us-section .testimonial-carousel .slick-list .slick-track {
  display: flex;
}
.home-container .about-us-section .testimonial-carousel .slick-list .slick-track .slick-slide {
  height: auto;
}
.home-container .about-us-section .testimonial-carousel .slick-list .slick-track .slick-slide > div {
  height: 100%;
}
.home-container .about-us-section .testimonial-carousel .slick-list .slick-track .slick-slide > div .ant-card {
  height: 100%;
}
.home-container .about-us-section .testimonial-carousel .slick-dots {
  bottom: 0;
  position: relative;
  margin-top: 16px;
}
.home-container .about-us-section .testimonial-carousel .slick-dots li button {
  background: var(--breadcrumbLink);
}
.home-container .about-us-section .testimonial-carousel .slick-dots .slick-active button {
  background: var(--system-Links);
}
.home-container .about-us-section .testimonial-carousel .slick-slide {
  padding: 0 22px;
}
@media only screen and (max-width: 1024px) {
  .home-container .about-us-section .testimonial-carousel .slick-slide {
    padding: 0 16px;
  }
}
@media only screen and (max-width: 767px) {
  .home-container .about-us-section .testimonial-carousel .slick-slide {
    padding: 0 8px;
  }
}
.home-container .about-us-section .testimonial-carousel .slick-slide .ant-card .ant-avatar {
  background: var(--primary-primary-tint-1);
  padding: 4px;
}
.home-container .about-us-section .testimonial-carousel .slick-slide .ant-card .ant-avatar img {
  width: auto;
  height: auto;
}
.home-container .about-us-section .testimonial-carousel .slick-arrow {
  height: 50px;
  width: 50px;
  background-color: var(--background);
  background-image: url('../assets/images/vector.png');
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%;
  z-index: 1;
}
.home-container .about-us-section .testimonial-carousel .slick-arrow.slick-disabled {
  opacity: 0;
}
.home-container .about-us-section .testimonial-carousel .slick-arrow.slick-prev {
  transform: scale(-1);
}
.home-container .footer-section {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets/images/rectangle-background.png');
}
@media only screen and (max-width: 1024px) {
  .home-container .footer-section .content {
    flex-direction: column;
    align-items: center;
    gap: 24px;
  }
}
@media only screen and (max-width: 1024px) {
  .home-container .footer-section .content .text-section {
    align-items: center;
  }
}
.home-container .footer-section .cards-container {
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 16px;
}
.home-container .footer-section .cards-container .ant-card {
  max-width: 234px;
}
@media only screen and (max-width: 767px) {
  .home-container .footer-section .cards-container {
    flex: 1 1 calc(100% - 16px);
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
  }
}
@media only screen and (max-width: 640px) {
  .home-container .footer-section .cards-container {
    flex: 1 1 calc(100% - 16px);
    justify-content: center;
    box-sizing: border-box;
    max-width: 100%;
    flex-flow: column;
    align-items: center;
  }
}
.home-container .footer-section .cards-container .card-content {
  flex: 1 1 calc(50% - 16px);
}
.home-container .footer-section .cards-container .card-content p {
  font-size: 12px;
}
.home-container .footer-section .cards-container .card-content .ant-btn {
  font-size: 16px;
  padding: 0px;
}
.home-container .footer-section .cards-container .card-content .know-more-btn {
  color: var(--primary-primary-shade-1);
}
.ant-rate {
  color: var(--system-Links);
  font-size: 14px;
}
